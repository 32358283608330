<template>
    <DatatablePageLayout>
        <template v-slot:datatable>
            <DataTable
                :config="datatableConfig"
                :elements="tracks"
                :stripe="true"
                :draggable="false"
                :sst="true"
                @action="handleAction"
                @filter="filter"
                :pagination="pagination"
            />
        </template>
    </DatatablePageLayout>
</template>

<script>

import { DateTime } from 'luxon';
import loader from "@/modules/Shared/Mixins/loader";
import PaginationService from "@/modules/Shared/Services/PaginationService";
import DataTable from '@/modules/Shared/Components/table/SKDataTable.vue'
import DatatablePageLayout from "@/modules/Shared/Pages/DatatablePageLayout.vue";
import TrackService from "@/modules/Admin/Services/TrackService";
import { formatSeconds } from '@/modules/Shared/Helpers/dateHelper';
import { download } from '@/modules/Shared/Helpers/fileHelper';

export default {
    name: "TracksPage",
    components: {DataTable, DatatablePageLayout},
    mixins: [loader],
    data() {
        return {
            tracks: [],
            datatableConfig: [
                { 'type': 'image', 'field': 'thumbnail', head: this.$t("tracks.tracks-list.thumbnail"), 'searchable': false, 'sorteable': false },
                { 'type': 'text', 'field': 'username', head: this.$t("tracks.tracks-list.user"), 'searchable': true, 'sorteable': false },
                { 'type': 'text', 'field': 'date', head: this.$t("tracks.tracks-list.date"), 'searchable': false, 'sorteable': false },
                { 'type': 'text', 'field': 'duration', head: this.$t("tracks.tracks-list.duration"), 'searchable': false, 'sorteable': false },
                { 'type': 'text', 'field': 'distance', head: this.$t("tracks.tracks-list.distance"), 'searchable': false, 'sorteable': false },
                { 'type': 'text', 'field': 'max_speed', head: this.$t("tracks.tracks-list.max_speed"), 'searchable': false, 'sorteable': false },
                { 'type': 'text', 'field': 'ascent', head: this.$t("tracks.tracks-list.ascent"), 'searchable': false, 'sorteable': false },
                { 'type': 'text', 'field': 'descent', head: this.$t("tracks.tracks-list.descent"), 'searchable': false, 'sorteable': false },
                { 'type': 'text', 'field': 'city', head: this.$t("tracks.tracks-list.city"), 'searchable': false, 'sorteable': false },
                { 'type': 'text', 'field': 'country', head: this.$t("tracks.tracks-list.country"), 'searchable': false, 'sorteable': false },
                { 'type': 'text', 'field': 'resortname', head: this.$t("tracks.tracks-list.resort"), 'searchable': false, 'sorteable': false },
                { 'type': 'actions', 'field': 'actions', head: this.$t("apps.photos.actions"), 'searchable': false, 'sorteable': false }
            ],
            pagination: {
                per_page: 30,
                total: 0,
            }
        }
    },
    async created() {
        await this.getTracks({ filters: [], page: 1 })
    },
    methods: {
        async getTracks(params) {
            await this.loadAndNotify(async () => {
                let trackResponse = await TrackService.getTracks(PaginationService.parseParameters(params))
                this.tracks = this.parseTracks(trackResponse.data)
                this.pagination = {
                    per_page: trackResponse.per_page,
                    total: trackResponse.total
                }
            }, true)
        },
        parseTracks(tracks) {
            return tracks.map((track) => ({
                ...track,
                username: track.user.username,
                duration: track.statistics.duration ? formatSeconds(parseInt(track.statistics.duration)) : '',
                distance: track.statistics.distance ? Math.round(track.statistics.distance) + ' m' : '',
                max_speed: track.statistics.max_speed ? track.statistics.max_speed.toFixed(2) + ' Km/h' : '',
                ascent: track.statistics.ascent ? track.statistics.ascent + ' m' : '',
                descent: track.statistics.descent ? track.statistics.descent + ' m' : '',
                date: DateTime.fromSeconds(parseInt(track.statistics.timestamp_start)).toFormat('dd-LL-yyyy'),
                city: track.location.city,
                country: track.location.country,
                resortname: track.resort ? track.resort.name : '',
                actions: [ 'edit', 'download' ]
            }))
        },
        handleAction(event, uuid) {
            const track = this.tracks.find(track => track.uuid === uuid)
            switch(event) {
                case 'edit':
                    this.$router.push({name: 'admin-track-validation', params: { uuid: uuid}})
                    break
                case 'download':
                    download(track.kml, track.uuid + '.kml')
                    break
            }
        },

        async filter(params) {
            await this.getTracks(params)
        },
    }
};
</script>
